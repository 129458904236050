import { Tag, Col, Icon, Cell, CellGroup, Tabbar, TabbarItem, NavBar, Dialog, Overlay, Toast } from "vant";
import { ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { getDateAndTypeInfo, postOrderMeal } from "@/api/services.js";
export default {
  setup() {
    let router = useRouter();
    const active = ref(0);
    const showLoading = ref(false);
    const show = ref(false);
    const phone = ref(router.currentRoute.value.query.phone);
    const pageTitle = ref("我要报餐-" + phone.value);
    const tabBars = ref(["/book?phone=" + phone.value, "/check?phone=" + phone.value, "/admin?phone=" + phone.value]);
    const dataAndTypeList = reactive([]);
    const choosedData = reactive([]); //选中的餐别信息

    const btnChoosedIndex = reactive([]);
    const noData = ref(true);
    const mealTypeArray = reactive([]);

    const getList = () => {
      if (phone.value) {
        getDateAndTypeInfo(phone.value).then(res => {
          console.log("getDateAndTypeInfo");

          if (res.data.code == 0) {
            //接收后台返回的数据，动态绑定到界面
            showLoading.value = false;
            show.value = false;
            dataAndTypeList.value = res.data.data;

            if (dataAndTypeList && dataAndTypeList.value.length > 0) {
              mealTypeArray.length = 0; //reactive 数组清空

              dataAndTypeList.value.forEach(element => {
                if (element && element.mealTypeList && element.mealTypeList.length > 0) {
                  element.mealTypeList.forEach(mealType => {
                    mealTypeArray.push(mealType);
                  });
                }
              });
            } //init


            noData.value = true;

            for (let i = 0; i < mealTypeArray.length; i++) {
              if (mealTypeArray[i] && mealTypeArray[i].indexOf("1") < 0) {
                noData.value = false;
                break;
              }
            }
          } else {
            Toast("无法获取信息，请稍候重试");
          }
        }, error => {
          console.log(error);
          Toast(error);
        });
      }
    };

    const isShowToday = item => {
      let flag = false;

      if (item.mealTypeList.length > 0) {
        for (let i = 0; i < item.mealTypeList.length; i++) {
          if (item.mealTypeList[i].indexOf("1") < 0) {
            flag = true;
            break;
          }
        }
      } else {
        flag = false;
      }

      return flag;
    };

    const chooseMeal = (item, meal) => {
      let p = reactive({
        Phone: phone,
        MealDate: item.mealDate,
        MealType: meal
      });
      console.log("choose meal");
      console.log(isExisted(p, 0));

      if (isExisted(p, 0)) {
        console.log("existed" + choosedData.length);
        removeMeal(p);
      } else {
        choosedData.push(p);
        console.log("push num:" + choosedData.length);
      }
    };

    const isChoosed = (item, meal) => {
      //当前是否应该被选中
      let p = reactive({
        Phone: phone,
        MealDate: item.mealDate,
        MealType: meal
      });
      return isExisted(p, 1);
    };

    const isExisted = (item, from) => {
      let res = false;

      if (choosedData && choosedData.length > 0) {
        for (let i = 0; i < choosedData.length; i++) {
          if (from == 0) {// console.log("-------isExisted test----");
            // console.log(choosedData[i].Phone);
            // console.log(item.Phone.value);
            // console.log(choosedData[i].Phone == item.Phone);
            // console.log(choosedData[i].MealDate == item.MealDate);
            // console.log(choosedData[i].MealType == item.MealType);
            // console.log("-------isExisted test end----");
          } //console.log(from);


          if (choosedData[i].Phone == item.Phone && choosedData[i].MealDate == item.MealDate && choosedData[i].MealType == item.MealType) {
            res = true;
            break;
          }
        }
      }

      return res;
    };

    const removeMeal = item => {
      let index = -1;

      if (choosedData && choosedData.length > 0) {
        for (let i = 0; i < choosedData.length; i++) {
          if (choosedData[i].Phone == item.Phone && choosedData[i].MealDate == item.MealDate && choosedData[i].MealType == item.MealType) {
            index = i;
            break;
          }
        }
      }

      if (index >= 0) {
        choosedData.splice(index, 1);
      }
    };

    const submitOrder = () => {
      if (!choosedData || choosedData.length == 0) {
        Toast("请点击选中餐别");
      } else {
        //提交报餐信息
        postOrderMeal(choosedData).then(res => {
          console.log(res.data); //置空数组

          choosedData.length = 0;

          if (res.data.code == 0 || res.data.code == -1) {
            show.value = true;
            choosedData.value = [];
            setTimeout(getList, 2000);
          } else if (res.data.code == 100) {
            alert("您已经点过该餐，请不要重复点餐");
          } else if (res.data.code == 200) {
            alert("已核销");
          } else if (res.data.code == 500) {
            alert("已过期");
          } else {
            //this.$toast.fail(res.message);
            alert(res.data.message);
          }
        }, error => {
          console.log(error);
        });
      }
    }; // const getValueFromUrl = (p) => {
    //   let url = decodeURI(window.location.search);
    //   console.log(url);
    //   let dataObj = {};
    //   if (url.indexOf("?") >= 0) {
    //     let strs = url.substr(1).split("&");
    //     strs.forEach((item) => {
    //       dataObj[item.split("=")[0]] = item.split("=")[1];
    //     });
    //   }
    //   return dataObj[p];
    // };


    return {
      active,
      pageTitle,
      show,
      phone,
      dataAndTypeList,
      choosedData,
      btnChoosedIndex,
      noData,
      mealTypeArray,
      getList,
      showLoading,
      isShowToday,
      removeMeal,
      isChoosed,
      submitOrder,
      chooseMeal,
      tabBars
    };
  },

  components: {
    [Tag.name]: Tag,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [NavBar.name]: NavBar,
    [Dialog.name]: Dialog,
    [Overlay.name]: Overlay
  },

  data() {
    return {
      getDateAndTypeInfo,
      postOrderMeal
    };
  },

  created() {
    if (!this.phone) {
      this.$router.push({
        path: "./no"
      });
    } else {
      this.getList();
    }
  },

  methods: {
    onClickRight() {
      this.$router.push({
        path: "/diningRules",
        query: {
          phone: this.phone
        }
      });
    },

    onClickLeft() {
      this.showLoading = true;
      this.getList();
    }

  }
};